<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo">
        <img src="assets/images/logos/logo.png">
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="logout" fxLayout="row" fxLayoutAlign="center" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
        <a class="nav-link accent" (click)="logout()">
            <mat-icon>exit_to_app</mat-icon><span class="logoutText"> Cerrar Sesión</span></a>
    </div>

    <!-- <div (click)="routingToAccount()" *ngIf="isAdmin" class="user" fxLayout="row" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <img class="avatar" src="https://ui-avatars.com/api/?background=random&name={{ first_name }} {{ first_surname }}">
        </div>
        <div>
            <h3 class="h3 username">Mi Cuenta</h3>
            <h5 class="h5 email hint-text mt-8">{{ first_name }} {{ first_surname }}</h5>
        </div>
    </div> -->

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>