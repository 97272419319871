import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IRouteName } from '@interfaces/route-name.interfeace';
import { RouteNameService } from '@services/route-name.service';

@Component({
  selector: 'app-select-route-name',
  templateUrl: './select-route-name.component.html',
  styleUrls: ['./select-route-name.component.scss'],
})
export class SelectRouteNameComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() appearance: string = 'fill';
  @Input() disabled: boolean = false;
  routeNames: IRouteName[] = [];
  selectedRouteName: IRouteName;

  // eslint-disable-next-line no-unused-vars
  constructor(private routeNameService: RouteNameService) {}

  ngOnInit(): void {
    this.getRouteNames();
  }

  getRouteNames() {
    this.routeNameService.getNames({}).subscribe((routeNames: any) => {
      console.log(routeNames);
      this.routeNames = routeNames.data;
      this.selectedRouteName = this.routeNames.find(
        routeName =>
          routeName.route_name_id === this.formGroup.value.route_name_id
      );
    });
  }

  onChange(e: any) {
    this.formGroup.patchValue({ route_name_id: e.value.route_name_id });
    this.selectedRouteName = e.value;
  }
}
