import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  message: string;
  icon: string;
  color: string;
  buttonText: string;
}
@Component({
  selector: 'app-alert-information',
  templateUrl: './alert-information.component.html',
  styleUrls: ['./alert-information.component.scss'],
})
export class AlertInformationComponent {
  message: string;
  icon: string;
  color: string;
  buttonText: string;

  constructor(
    // eslint-disable-next-line no-unused-vars
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    // eslint-disable-next-line no-unused-vars
    public modal: MatDialogRef<AlertInformationComponent>
  ) {
    this.message = this.data.message;
    this.icon = this.data.icon;
    this.color = this.data.color;
    this.buttonText = this.data.buttonText;
  }

  onClose(): void {
    this.modal.close();
  }
}
