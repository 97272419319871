import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ISchool } from '@interfaces/admin/school.interface';
import { SchoolsService } from '@services/admin/schools.service';

@Component({
  selector: 'app-select-school',
  templateUrl: './select-school.component.html',
  styleUrls: ['./select-school.component.scss'],
})
export class SelectSchoolComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() disabled: boolean = false;
  @Input() appearance: string = 'fill';
  schools: ISchool[] = [];
  selectedSchool: ISchool;
  // eslint-disable-next-line no-unused-vars
  constructor(private schoolsService: SchoolsService) {}

  ngOnInit(): void {
    this.getSchools();
  }

  /**
   * Obtener colegios activos
   * @autor Adrian Avendano Concha
   * @date 20/01/2022
   */
  getSchools() {
    this.schoolsService.getActiveSchools().subscribe((schools: ISchool[]) => {
      this.schools = schools;
      this.selectedSchool = this.schools.find(
        school => school.school_id === this.formGroup.value.school_id
      );
    });
  }

  onChange(e: any) {
    this.formGroup.patchValue({ school_id: e.value.school_id });
    this.selectedSchool = e.value;
  }
}
