import { IAlmuerzoResponse } from '@server-responses/almuerzo-response.interface';

export class Almuerzo {
  school_id: string;
  alimentacion_id: string;
  school_grade: string;
  service_detail_id: string;
  amount: string;
  service_price_id: string;
  pricing: string;
  year: string;
  pricing_externo: string;

  constructor(attrs?: IAlmuerzoResponse) {
    if (!attrs) {
      return;
    }
    this.school_id = attrs.school_id;
    this.school_grade = attrs.school_grade;
    this.service_detail_id = attrs.service_detail_id;
    this.amount = attrs.pricing;
    this.year = attrs.year;
    this.pricing_externo = attrs.pricing_externo;
  }
}
