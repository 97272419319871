import { ISchoolPayload } from '@interfaces/school-payload.interface';

export class School {
  id: string;
  name: string;
  calendar: string;

  constructor(attrs?: ISchoolPayload) {
    if (!attrs) {
      return;
    }
    this.id = attrs.school_id;
    this.name = attrs.name;
  }
}
