<div mat-dialog-content class="container">
  <mat-icon [color]="color">{{ icon }}</mat-icon>
  <h1>{{ message }}</h1>
</div>
<div mat-dialog-actions class="buttons">
  <button mat-raised-button [mat-dialog-close]="true" [color]="color">
    {{ buttonText }}
  </button>
  <button cdkFocusInitial mat-dialog-close mat-raised-button color="warn">
    No, Cancelar
  </button>
</div>
