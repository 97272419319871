import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteDistrictInput } from '@interfaces/route-district.input';

const ApiURL =
  'https://outiqyoot8.execute-api.us-east-1.amazonaws.com/test/route-request-district';

@Injectable({
  providedIn: 'root',
})
export class RouteDistrictService {
  // eslint-disable-next-line no-unused-vars
  constructor(private httpClient: HttpClient) {}

  getDistricts(params: any) {
    return this.httpClient.get(ApiURL, { params });
  }

  createDistrict(params: RouteDistrictInput) {
    return this.httpClient.post(ApiURL, params);
  }

  updateDistrict(route_city_id: string, params: Partial<RouteDistrictInput>) {
    return this.httpClient.put(ApiURL, { ...params, route_city_id });
  }
}
