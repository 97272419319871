<mat-form-field appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <input matInput #addresstext autocomplete="off" [formControlName]="name" />
</mat-form-field>
<agm-map
  [streetViewControl]="false"
  [clickableIcons]="false"
  [latitude]="latitude"
  [longitude]="longitude"
  [zoom]="zoom"
  (mapDblClick)="mapDblClicked($event)">
  <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
</agm-map>
