import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteCityInput } from '@interfaces/route-city.input';

const ApiURL =
  'https://outiqyoot8.execute-api.us-east-1.amazonaws.com/test/route-request-city';

@Injectable({
  providedIn: 'root',
})
export class RouteCityService {
  // eslint-disable-next-line no-unused-vars
  constructor(private httpClient: HttpClient) {}

  getCities(params?: any) {
    return this.httpClient.get(ApiURL, { params });
  }

  createCity(params: RouteCityInput) {
    return this.httpClient.post(ApiURL, params);
  }

  updateCity(route_city_id: string, params: Partial<RouteCityInput>) {
    return this.httpClient.put(ApiURL, { ...params, route_city_id });
  }
}
