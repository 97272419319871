import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IRouteCity } from '@interfaces/route-city.interface';
import { RouteCityService } from '@services/route-city.service';

@Component({
  selector: 'app-select-route-cities',
  templateUrl: './select-route-cities.component.html',
  styleUrls: ['./select-route-cities.component.scss'],
})
export class SelectRouteCitiesComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() appearance: string = 'fill';
  @Input() disabled: boolean = false;
  routeCities: IRouteCity[] = [];
  selectedRouteCity: IRouteCity;

  // eslint-disable-next-line no-unused-vars
  constructor(private routeCityService: RouteCityService) {}

  ngOnInit(): void {
    this.getRouteCities();
  }

  getRouteCities() {
    this.routeCityService.getCities().subscribe((routeCities: any) => {
      this.routeCities = routeCities.data;
    });
  }

  onChange(e: any) {
    this.formGroup.patchValue({ route_city: e.value });
    this.selectedRouteCity = e.value;
  }
}
