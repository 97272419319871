import { IChildrenPayload } from '@interfaces/children-payload.interface';
import { School } from './school';

/**
 * @update Adrian Avendano Concha
 * @date 25/01/2022
 */
export class Child {
  id: string;
  user_id: string;
  address: any;
  agreements: any;

  certificateDocuments: any;
  birthdate: string;
  gender: number;
  hasSpecialNeeds: boolean;

  identityDocuments: any;

  lastName: string;
  firstSurname: string;
  secondSurname: string;
  liveSameCity: boolean;
  name: string;
  scholarService: string;
  school: School;
  schoolGrade: string;
  specialNeed: string[];
  formalEducation: string;
  registrationStatus: number;
  paymentStatus: string;
  hubspot_id: string;
  rutaSigned = false;
  alimentacionSigned = false;
  matriculaSigned = false;
  hojaMatriculaSigned = false;

  constructor(attrs?: IChildrenPayload) {
    if (!attrs) {
      return;
    }
    this.id = attrs.child_id;
    this.user_id = attrs.user_id;
    this.agreements = attrs.agreements;
    this.address = attrs.address;
    this.birthdate = attrs.birthdate;
    this.gender = attrs.gender;
    this.hasSpecialNeeds = attrs.has_special_needs;
    this.firstSurname = attrs.first_surname;
    this.secondSurname = attrs.second_surname;
    this.liveSameCity = attrs.lives_with_parent;
    this.name = attrs.first_name;
    this.scholarService = attrs.scholar_service;
    this.school = new School(attrs.school);
    this.formalEducation = this.setFormalEducation(this.school);
    this.schoolGrade = attrs.school_grade;
    this.specialNeed = attrs.special_need?.split(',');
    this.certificateDocuments = attrs.certificate_document;
    this.identityDocuments = attrs.identity_document;
    this.registrationStatus = attrs.registration_status;
    this.paymentStatus = attrs.payment_status;
    this.hubspot_id = attrs.hubspot_id;
  }

  /** @deprecate This was here because initially only one school could sign */
  get canSignDocusign(): boolean {
    return true;
  }

  get areContractsSigned(): boolean {
    return this.agreements?.isTuitionContractSigned;
  }

  private setFormalEducation(
    school: School
  ): 'Presencial' | 'Innova en Casa' | 'Otros' {
    switch (school.name) {
      case 'Innova en Casa':
        return 'Innova en Casa';
      case 'Otros':
        return 'Otros';
      default:
        return 'Presencial';
    }
  }
}
