import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '@guards/auth-guard.service';

import { AuthComponent } from './auth.component';

const routes = [
  {
    path: 'i',
    component: AuthComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/i/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'children-info',
        loadChildren: () =>
          import('./children-info/children-info.module').then(
            m => m.ChildrenInfoModule
          ),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'messages',
        loadChildren: () =>
          import('./messages/messages.module').then(m => m.MessagesModule),
      },
      {
        path: 'my-account',
        loadChildren: () =>
          import('./my-account/my-account.module').then(m => m.MyAccountModule),
      },
      {
        path: 'payment',
        loadChildren: () =>
          import('./payment/payment.module').then(m => m.PaymentModule),
      },
      {
        path: 'payment-history',
        loadChildren: () =>
          import('./payment-history/payment-history.module').then(
            m => m.PaymentHistoryModule
          ),
      },
      {
        path: 'payment-tuition',
        loadChildren: () =>
          import('./payment-tuition/payment-tuition.module').then(
            m => m.PaymentTuitionModule
          ),
      },
      {
        path: 'bill',
        loadChildren: () =>
          import('./payment-history/bill/bill.module').then(m => m.BillModule),
      },
      {
        path: 'screening',
        loadChildren: () =>
          import('./screening/screening.module').then(m => m.ScreeningModule),
      },
      {
        path: 'update-info',
        loadChildren: () =>
          import('./update-info/update-info.module').then(
            m => m.UpdateInfoModule
          ),
      },
      {
        path: 'payment-pension',
        loadChildren: () =>
          import('./payment-pension/payment-pension.module').then(
            m => m.PaymentPensionModule
          ),
      },
      {
        path: 'routeRequest',
        loadChildren: () =>
          import('./route-requests/route-requests.module').then(
            m => m.RouteRequestsModule
          ),
      },
      {
        path: 'payment-extra',
        loadChildren: () =>
          import('./payment-extra/payment-extra.module').then(
            m => m.PaymentExtraModule
          ),
      },
      {
        path: 'landing-kit',
        loadChildren: () =>
          import('./landing-kit/landing-kit.module').then(
            m => m.LandingKitModule
          ),
      },
      {
        path: 'qualifications',
        loadChildren: () =>
          import('./school-grades/school-grades.module').then(
            m => m.SchoolGradesModule
          ),
      },
      {
        path: 'payment-aditionals',
        loadChildren: () =>
          import('./payment-aditionals/payment-aditionals.module').then(
            m => m.PaymentAditionalsModule
          ),
      },
      {
        path: 'route-requests',
        loadChildren: () =>
          import('./route-requests/route-requests.module').then(
            m => m.RouteRequestsModule
          ),
      },
      {
        path: 'badges',
        loadChildren: () =>
          import('./badges/badges.module').then(m => m.BadgesModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
