import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertInformationComponent } from '../alert-information/alert-information.component';

export interface DialogData {
  message: string;
  icon: string;
  color: string;
  buttonText: string;
  confirmAlert: boolean;
}

@Component({
  selector: 'app-alert-confirmation',
  templateUrl: './alert-confirmation.component.html',
  styleUrls: ['./alert-confirmation.component.scss'],
})
export class AlertConfirmationComponent {
  message: string;
  icon: string;
  color: string;
  buttonText: string;
  constructor(
    // eslint-disable-next-line no-unused-vars
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    // eslint-disable-next-line no-unused-vars
    public modal: MatDialogRef<AlertInformationComponent>
  ) {
    this.message = this.data.message;
    this.icon = this.data.icon;
    this.color = this.data.color;
    this.buttonText = this.data.buttonText;
  }
}
