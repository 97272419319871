import { FuseNavigation } from '@fuse/types';

export const navigationExternal: FuseNavigation[] = [
    {
        id: 'payment-extra',
        title: 'Actividades extracurriculares',
        type: 'item',
        icon: 'ballot',
        url: '/i/payment-extra',
    }
];
