import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IRouteDistrict } from '@interfaces/route-district.interface';
import { RouteDistrictService } from '@services/route-district.service';

@Component({
  selector: 'app-select-route-districts',
  templateUrl: './select-route-districts.component.html',
  styleUrls: ['./select-route-districts.component.scss'],
})
export class SelectRouteDistrictsComponent implements OnInit {
  @Input() route_city_id: number;
  @Input() formGroup: FormGroup;
  @Input() appearance: string = 'fill';
  @Input() disabled: boolean = false;
  routeDistricts: IRouteDistrict[] = [];
  selectedRouteDistrict: IRouteDistrict;

  // eslint-disable-next-line no-unused-vars
  constructor(private routeDistrictService: RouteDistrictService) {}

  ngOnInit(): void {
    this.getRouteDistricts();
  }

  getRouteDistricts() {
    this.routeDistrictService
      .getDistricts({ route_city_id: this.route_city_id })
      .subscribe((routeDistricts: any) => {
        console.log(routeDistricts);
        this.routeDistricts = routeDistricts.data;
        this.selectedRouteDistrict = this.routeDistricts.find(
          routeDistrict =>
            routeDistrict.route_district_id ===
            this.formGroup.value.route_district_id
        );
      });
  }

  onChange(e: any) {
    this.formGroup.patchValue({ route_district_id: e.value.route_district_id });
    this.selectedRouteDistrict = e.value;
  }
}
