<div class="container-header">
    <div class="fuse-card auto-width p-20 mb-20">
        <h4 fxFlex fxLayout="row">
            <mat-icon class="mr-10">{{ icon }}</mat-icon>{{ urlLink }} {{ selectedChild?.name }}
        </h4>
        <div class="align-left">
            <div class="select">
                <mat-form-field fxFlex.gt-md="20" class="form-field" appearance="outline">
                    <mat-label>Escoge a tu hija/o</mat-label>
                    <mat-select (selectionChange)="childSelection($event)" [value]="selectedChild">
                        <mat-select-trigger *ngIf="selectedChild">
                            <mat-icon>face</mat-icon>
                            {{ selectedChild.name }}
                        </mat-select-trigger>
                        <mat-option *ngFor="let child of children" [value]="child">
                            <mat-icon>face</mat-icon>
                            {{ child.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <a mat-raised-button class="btn-secondary" [routerLink]="['/i/messages']">
                <mat-icon>notifications</mat-icon>Mensajes
            </a>
            <button mat-raised-button class="btn-secondary">
        <mat-icon>help_outline</mat-icon>
        Ayuda
      </button>
        </div>
    </div>
</div>