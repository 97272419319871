import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IRouteAddress } from '@interfaces/route-address.interface';

import { GoogleService } from '@services/google.service';

@Component({
  selector: 'app-google-places',
  templateUrl: './google-places.component.html',
  styleUrls: ['./google-places.component.scss'],
})
export class GooglePlacesComponent implements OnInit, AfterViewInit {
  @Input() addressType: string;
  @Input() label: string;
  @Input() name: string;
  // eslint-disable-next-line no-unused-vars
  @Input() onChange: (address: IRouteAddress, type: string) => void;
  @ViewChild('addresstext') addresstext: any;
  showDetails = false;
  place!: object;

  establishmentAddress: Object;

  formattedAddress: string;
  formattedEstablishmentAddress: string;

  addressForm!: FormGroup;
  googleAddress!: any;

  latitude: number = 4.6967517;
  longitude: number = -74.2032983;
  zoom: number = 15;

  constructor(
    // eslint-disable-next-line no-unused-vars
    private googleAddressService: GoogleService,
    // eslint-disable-next-line no-unused-vars
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initializeAddressForm();
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  initializeAddressForm() {
    const initialAddress: any = {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
    };
    this.googleAddress = initialAddress;

    this.addressForm = this.formBuilder.group({
      addressLine1: new FormControl(this.googleAddress.addressLine1, [
        Validators.required,
        Validators.maxLength(200),
      ]),
    });
  }

  patchGoogleAddress() {
    const streetNo = this.googleAddressService.getStreetNumber(this.place);
    const street = this.googleAddressService.getStreet(this.place);
    let googleAddress: any = {
      addressLine1: `${streetNo === undefined ? '' : streetNo} ${
        street === undefined ? '' : street
      }`,
      addressLine2: '',
      postalCode: this.googleAddressService.getPostCode(this.place),
      city: this.googleAddressService.getLocality(this.place),
      state: this.googleAddressService.getState(this.place),
      country: this.googleAddressService.getCountryShort(this.place),
    };
    this.addressForm.markAllAsTouched();
    this.patchAddress(googleAddress);
  }

  patchAddress(address: any) {
    if (this.addressForm !== undefined) {
      this.addressForm.get('addressLine1')!.patchValue(address.addressLine1);
      this.addressForm.get('addressLine2')!.patchValue(address.addressLine2);
      this.addressForm.get('postalCode')!.patchValue(address.postalCode);
      this.addressForm.get('city')!.patchValue(address.city);
      this.addressForm.get('state')!.patchValue(address.state);
      this.addressForm.get('country')!.patchValue(address.country);
    }
  }

  async mapDblClicked(e: any) {
    this.latitude = e.coords.lat;
    this.longitude = e.coords.lng;

    const response = await this.googleAddressService.getAddressByLatLng(
      e.coords.lat,
      e.coords.lng
    );

    this.place = response[1];

    const addressGoogle = response[1].address_components;
    const latitude = e.coords.lat;
    const longitude = e.coords.lng;
    const address = addressGoogle[0]['long_name'];
    const district_name = addressGoogle[1]['long_name'];
    const city_name = addressGoogle[2]['long_name'];
    const county_name = addressGoogle['long_name'];
    const department_name = addressGoogle['long_name'];

    this.onChange(
      {
        address,
        district_name,
        city_name,
        county_name,
        department_name,
        latitude,
        longitude,
      },
      this.name
    );

    console.log(this.place);

    this.formattedAddress = this.googleAddressService.getFormattedAddress(
      this.place
    );
    this.patchGoogleAddress();
    this.showDetails = true;
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      {
        componentRestrictions: { country: 'CO' },
        types: [this.addressType], // 'establishment' / 'address' / 'geocode' // we are checking all types
      }
    );

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.place = autocomplete.getPlace();
      const latitude = this.place['geometry']['location']?.lat();
      const longitude = this.place['geometry']['location']?.lng();
      const address = this.place['address_components'][0]['long_name'];
      const district_name = this.place['address_components'][1]['long_name'];
      const city_name = this.place['address_components'][2]['long_name'];
      const county_name = this.place['address_components'][4]['long_name'];
      const department_name = this.place['address_components'][3]['long_name'];

      this.latitude = latitude;
      this.longitude = longitude;

      this.onChange(
        {
          address,
          district_name,
          city_name,
          county_name,
          department_name,
          latitude,
          longitude,
        },
        this.name
      );

      console.log(this.place);

      this.formattedAddress = this.googleAddressService.getFormattedAddress(
        this.place
      );
      this.patchGoogleAddress();
      this.showDetails = true;
    });
  }
}
