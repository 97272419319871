import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { IParentProfile } from '@server-responses/parent-profile.interface';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private httpClient: HttpClient, private router: Router) {}

  private profileUpdatedSource = new BehaviorSubject<IParentProfile>(null);

  public profileUpdated = this.profileUpdatedSource.asObservable();

  profileUpdate(profile: IParentProfile): void {
    this.profileUpdatedSource.next(profile);
  }

  getComingEvents(): any {
    return this.httpClient.get(`${environment.API}/coming_events`);
  }

  getGooglePermissions(): any {
    return this.httpClient.post(
      `${environment.API}/oauth/requests/google-calendar`,
      ''
    );
  }

  getPaymentStatus(): any {
    return this.httpClient.get(`${environment.API}/payments/status`);
  }

  getProfile(): any {
    return this.httpClient
      .get(`${environment.API_ENDPOINTS}/aolservices/profile`)
      .pipe(
        tap(({ profile }: IParentProfile) => {
          if (!profile.parent.is_onboarding_completed) {
            this.router.navigateByUrl('/i/update-info');
          }
        })
      );
  }

  getScheduleStatus(): any {
    return this.httpClient.get(`${environment.API}/registration_status`);
  }

  googleRegistration(payload: any): any {
    return this.httpClient.post(
      `${environment.API}/oauth/registrations/google-calendar`,
      payload
    );
  }
  getNotifications(parentId: string): any {
    return this.httpClient.get(
      `${environment.API}/notifications/parent/${parentId}`
    );
  }
}
