import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from '../../../navigation';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'vertical-layout-1',
  templateUrl: './layout-1.component.html',
  styleUrls: ['./layout-1.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  showHeader = false;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    // Set the defaults
    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.fuseConfig = config;
      });
    this.activatedRoute.url.subscribe(() => {
      const url = this.router.url;

      if (url.includes('/i/dashboard')) {
        this.showHeader = true;
      }

      if (url.includes('/i/qualifications')) {
        this.showHeader = true;
      }
      if (url.includes('/i/children-info')) {
        this.showHeader = true;
      }
      if (url.includes('/i/payment-pension')) {
        this.showHeader = true;
      }
      if (url.includes('/i/payment-extra')) {
        this.showHeader = true;
      }
      if (url.includes('/i/route-requests')) {
        this.showHeader = true;
      }
      if (url.includes('/i/badges')) {
        this.showHeader = true;
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
