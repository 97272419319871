import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminService } from '@services/admin.service';
import { AuthService } from '@services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(
    private adminService: AdminService,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url !=
      `https://innova-family.auth.us-east-1.amazoncognito.com/oauth2/token`
    ) {
      const authorization = this.getAutorization();

      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + authorization,
        },
      });
    }

    return next.handle(request);
  }

  private getAutorization(): string {
    return this.authService.token || this.adminService.token || '';
  }
}
