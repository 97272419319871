import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../alert-information/alert-information.component';

@Component({
  selector: 'app-alert-loading',
  templateUrl: './alert-loading.component.html',
  styleUrls: ['./alert-loading.component.scss'],
})
export class AlertLoadingComponent {
  message: string;

  constructor(
    // eslint-disable-next-line no-unused-vars
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.message = this.data.message;
  }
}
