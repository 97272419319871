<mat-form-field [appearance]="appearance">
  <mat-label>Selecciona el proveedor de ruta</mat-label>
  <mat-select
    [disabled]="disabled"
    (selectionChange)="onChange($event)"
    [value]="selectedProvider">
    <mat-select-trigger *ngIf="selectedProvider">
      {{ selectedProvider?.name }}
    </mat-select-trigger>
    <mat-option
      *ngFor="let routerProvider of routeProviders"
      [value]="routerProvider">
      {{ routerProvider?.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
