import { IRutasResponse } from '@server-responses/rutas-response.interface';

export class Ruta {
  school_id: string;
  ruta_id: string;
  rutaType: string;
  service_detail_id: string;
  amount: string;
  amountMiddle: string;
  cupo: string;
  address: string;

  constructor(attrs?: IRutasResponse) {
    if (!attrs) {
      return;
    }
    this.school_id = attrs.school_id;
    this.ruta_id = attrs.ruta_id;
    this.rutaType = attrs.tipo_ruta;
    this.service_detail_id = attrs.service_detail_id;
    this.amount = attrs.valor_ruta;
    this.amountMiddle = attrs.valor_media_ruta;
    this.cupo = attrs.cupo;
    this.address = attrs.barrio;
  }
}
