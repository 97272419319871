import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Child } from '@models/child';
import { set } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ChildrenService } from './children.service';

@Injectable({
  providedIn: 'root',
})

export class StoreService {
  selectedChild = new BehaviorSubject<Child>(null);
  selectedChild$ = this.selectedChild.asObservable();
  
  constructor(
    // eslint-disable-next-line no-unused-vars
    private childrenService: ChildrenService,
    
  ) {
    if(!this.selectedChild.getValue()){
      //this.getChildren();
    }
  }

  public setChildSelected(selectedChild: Child): void {
    this.selectedChild.next(selectedChild);
  }

  // async getChildren(): Promise<void> {
  //   this.childrenService.getChildren().subscribe((children: Child[]) => {
  //     this.setChildSelected(children[0])
  //   });
  // }
}
