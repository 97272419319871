import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule,
} from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { ApiVersionIterceptor } from '@interceptors/api-version.interceptor';
import { AuthorizationInterceptor } from '@interceptors/authorization.interceptor';
import { HttpErrorInterceptor } from '@interceptors/http-error.interceptor';
import { PlatformInterceptor } from '@interceptors/platform.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { fuseConfig } from './fuse-config';
import { LayoutModule } from './layout/layout.module';
import { AdminModule } from './main/admin/admin.module';
import { AuthModule } from './main/auth/auth.module';
import { UnauthModule } from './main/unauth/unauth.module';
import { AdminGuard } from './shared/guards/admin-guard.service';
import { AuthGuard } from './shared/guards/auth-guard.service';
import { UnauthGuard } from './shared/guards/unauth-guard.service';
import { AuthService } from './shared/services/auth.service';
import { SharedModule } from './shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialModule } from './material/material.module';
import { ProviderModule } from './main/provider/provider.module';
const appRoutes: Routes = [
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // material
    MatButtonModule,
    MatIconModule,
    MatMomentDateModule,
    MatTabsModule,
    MatDialogModule,
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    LayoutModule,
    AdminModule,
    AuthModule,
    UnauthModule,
    ProviderModule,

    SharedModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
    }),
    MaterialModule,
  ],
  providers: [
    AdminGuard,
    AuthService,
    AuthGuard,
    UnauthGuard,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiVersionIterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PlatformInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
