<mat-form-field [appearance]="appearance">
  <mat-label>Selecciona el nombre</mat-label>
  <mat-select
    [disabled]="disabled"
    (selectionChange)="onChange($event)"
    [value]="selectedRouteName">
    <mat-select-trigger *ngIf="selectedRouteName">
      {{ selectedRouteName?.name }}
    </mat-select-trigger>
    <mat-option *ngFor="let routeName of routeNames" [value]="routeName">
      {{ routeName?.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
