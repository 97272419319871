<mat-form-field [appearance]="appearance">
  <mat-label>Selecciona Colegio</mat-label>
  <mat-select
    [disabled]="disabled"
    (selectionChange)="onChange($event)"
    [value]="selectedSchool">
    <mat-select-trigger *ngIf="selectedSchool">
      {{ selectedSchool?.name }}
    </mat-select-trigger>
    <mat-option *ngFor="let school of schools" [value]="school">
      {{ school?.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
