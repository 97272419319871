import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { LayoutModule } from 'app/layout/layout.module';
import { SharedModule } from 'app/shared/shared.module';
import { ProviderRoutingModule } from './provider-routing.module';
import { ProviderComponent } from './provider.component';

@NgModule({
  declarations: [ProviderComponent],
  imports: [
    CommonModule,
    LayoutModule,
    ProviderRoutingModule,
    SharedModule,
    FuseSharedModule,
  ],
  bootstrap: [ProviderComponent],
})
export class ProviderModule {}
