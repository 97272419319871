import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IRouteProvider } from '@interfaces/route-provider.interface';
import { RouteProviderService } from '@services/route-provider.service';

@Component({
  selector: 'app-select-route-provider',
  templateUrl: './select-route-provider.component.html',
  styleUrls: ['./select-route-provider.component.scss'],
})
export class SelectRouteProviderComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() appearance: string = 'fill';
  @Input() disabled: boolean = false;
  routeProviders: IRouteProvider[] = [];
  selectedProvider: IRouteProvider;
  // eslint-disable-next-line no-unused-vars
  constructor(private routeProviderService: RouteProviderService) {}

  ngOnInit(): void {
    this.getRouteProviders();
  }

  getRouteProviders() {
    this.routeProviderService
      .getProviders()
      .subscribe((routeProviders: IRouteProvider[]) => {
        this.routeProviders = routeProviders;
        this.selectedProvider = routeProviders.find(
          provider =>
            provider.route_provider_id ===
            this.formGroup.value.route_provider_id
        );
      });
  }

  onChange(e: any) {
    this.formGroup.patchValue({ route_provider_id: e.value.route_provider_id });
    this.selectedProvider = e.value;
  }
}
