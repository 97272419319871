import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ExportService {
  constructor(private httpClient: HttpClient) {}

  public exportToExcel(data: any[], fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  }

  ExportExcelget(): any {
    return this.httpClient.get(
      'https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/reporteMatriculas'
    );
  }

  public exportToCsv(data: any[], fileName: string): any {
    const replacer = (key, value) => (value === null ? '' : value);
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    FileSaver.saveAs(blob, fileName + '.csv');
  }
}
