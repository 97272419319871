import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProviderComponent } from './provider.component';

const routes = [
  {
    path: 'provider',
    component: ProviderComponent,
    //canActivate: [ProviderGuard],
    children: [
      { path: '', redirectTo: 'provider/login', pathMatch: 'full' },
      {
        path: 'route-school',
        loadChildren: () =>
          import('./route-school-list/route-school-list.module').then(
            m => m.RouteSchoolListModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProviderRoutingModule {}
