<mat-form-field [appearance]="appearance">
  <mat-label>Selecciona el barrio</mat-label>
  <mat-select
    [disabled]="disabled"
    (selectionChange)="onChange($event)"
    [value]="selectedRouteDistrict">
    <mat-option
      *ngFor="let routeDistrict of routeDistricts"
      [value]="routeDistrict">
      {{ routeDistrict?.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
