import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'environments/environment';

import decode from 'jwt-decode';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IChildrenPayload } from '@interfaces/children-payload.interface';
import { IChildrenResponse } from '@server-responses/children-response.interface';
import { Child } from '@models/child';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private router: Router
  ) {}

  set token(jwt: string) {
    this.cookieService.set(environment.COOKIE, jwt, null, '/');
  }

  get token(): string {
    return this.cookieService.get(environment.COOKIE);
  }

  get userSession(): any {
    return decode(this.token);
  }

  deleteSessionCookies(): void {
    this.cookieService.delete(environment.ADMIN_COOKIE, '/admin');
    this.cookieService.delete(environment.COOKIE, '/');
    localStorage.clear();
  }

  generatePasswordRecovery(payload: any): any {
    return this.httpClient.post(`${environment.API}/recover_password`, payload);
  }

  logout(): void {
    this.deleteSessionCookies();

    this.router.navigateByUrl('/');
  }

  recoverPassword(payload: any): any {
    return this.httpClient.put(`${environment.API}/change_password`, payload);
  }

  isAuthenticated(): any {
    return this.cookieService.get(environment.COOKIE) ? true : false;
  }

  /**
   * @update Adrian Avendano Concha
   * @date 17/01/2022
   */
  getChildren(): any {
    return this.httpClient.get(`${environment.API}/children`).pipe(
      map((response: IChildrenResponse) => {
        return response.children.map((child: IChildrenPayload) => {
          return new Child(child);
        });
      })
    );
  }

  /**
   * Obtener la cookie que guarda el id dl colegio del estudiante
   * @author Adrian Avendano Concha
   * @date 25/02/2022
   */
  getSchoolType(): boolean {
    if (this.cookieService.get(environment.SELECTED_SCHOOL) == 'true') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Crear una cookie con el id del colegio del estudiante.
   * @author Adrian Avendano Concha
   * @date 25/02/2022
   */
  setSchoolType(id: string): void {
    this.cookieService.set(environment.SELECTED_SCHOOL, id, null, '/');
  }

  /**
   * Finalizar sesión cognito
   * @author Adrian Avendano Concha
   * @date 22/03/2022
   */
  sessionFailed(msj: string): void {
    localStorage.clear();
    localStorage.setItem('login', msj);
    this.cookieService.deleteAll();
    this.logoutUser();
  }

  /**
   * Send data of the user entered and logout both in the cognito and in the application.
   * @author Adrian Avendano Concha
   * @date 15/03/2022
   */
  logoutUser(): void {
    let urlGoogLogout = environment.cognito_endpoint;
    urlGoogLogout += 'logout?client_id=';
    urlGoogLogout += environment.clientId;
    urlGoogLogout += '&logout_uri=';
    urlGoogLogout += environment.app_login;
    window.location.href = urlGoogLogout;
  }
}
