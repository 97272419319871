<mat-form-field [appearance]="appearance">
  <mat-label>Selecciona la ciudad</mat-label>
  <mat-select
    [disabled]="disabled"
    (selectionChange)="onChange($event)"
    [value]="selectedRouteCity">
    <mat-option *ngFor="let routeCity of routeCities" [value]="routeCity">
      {{ routeCity?.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
