import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteNameInput } from '@interfaces/route-name.input';

const ApiURL =
  'https://outiqyoot8.execute-api.us-east-1.amazonaws.com/test/route-request-name';

@Injectable({
  providedIn: 'root',
})
export class RouteNameService {
  // eslint-disable-next-line no-unused-vars
  constructor(private httpClient: HttpClient) {}

  getNames(params: any) {
    return this.httpClient.get(ApiURL, { params });
  }

  createName(params: RouteNameInput) {
    return this.httpClient.post(ApiURL, params);
  }

  updateName(route_name_id: string, params: Partial<RouteNameInput>) {
    return this.httpClient.put(ApiURL, { ...params, route_name_id });
  }
}
