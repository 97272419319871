import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteProviderInput } from '@interfaces/route-provider.input';

const ApiURL =
  'https://outiqyoot8.execute-api.us-east-1.amazonaws.com/test/route-request-provider';

@Injectable({
  providedIn: 'root',
})
export class RouteProviderService {
  // eslint-disable-next-line no-unused-vars
  constructor(private httpClient: HttpClient) {}

  getProviders() {
    return this.httpClient.get(ApiURL);
  }

  createProvider(params: RouteProviderInput) {
    return this.httpClient.post(ApiURL, params);
  }

  updateProvider(
    route_provider_id: string,
    params: Partial<RouteProviderInput>
  ) {
    return this.httpClient.put(ApiURL, { ...params, route_provider_id });
  }
}
