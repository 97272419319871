import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AgmCoreModule } from '@agm/core';

import { HeaderComponent } from './components/auth/header/header.component';

import { HeaderLessTabs } from './directives/header-less-tabs.directive';
import { ExportService } from './services/export.service';

import { GooglePlacesComponent } from './components/google-places/google-places.component';
import { MaterialModule } from 'app/material/material.module';
import { SelectSchoolComponent } from './components/select-school/select-school.component';
import { AlertInformationComponent } from './components/alerts/alert-information/alert-information.component';
import { SelectRouteProviderComponent } from './components/select-route-provider/select-route-provider.component';
import { AlertConfirmationComponent } from './components/alerts/alert-confirmation/alert-confirmation.component';
import { SelectRouteNameComponent } from './components/select-route-name/select-route-name.component';
import { AlertLoadingComponent } from './components/alerts/alert-loading/alert-loading.component';
import { SelectRouteCitiesComponent } from './components/select-route-cities/select-route-cities.component';
import { SelectRouteDistrictsComponent } from './components/select-route-districts/select-route-districts.component';

@NgModule({
  exports: [
    CommonModule,
    GooglePlacesComponent,
    HeaderComponent,
    HeaderLessTabs,
    SelectRouteProviderComponent,
    SelectSchoolComponent,
    SelectRouteNameComponent,
    SelectRouteCitiesComponent,
    SelectRouteDistrictsComponent,
    TranslateModule,
  ],
  imports: [
    RouterModule,
    CommonModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBUAgVr0YF-Bj8wPMH7V8i9lRBxd5afCVk',
      libraries: ['places', 'geometry', 'drawing', 'visualization'],
    }),
  ],
  declarations: [
    HeaderLessTabs,
    HeaderComponent,
    GooglePlacesComponent,
    SelectSchoolComponent,
    AlertInformationComponent,
    SelectRouteProviderComponent,
    AlertConfirmationComponent,
    SelectRouteNameComponent,
    AlertLoadingComponent,
    SelectRouteCitiesComponent,
    SelectRouteDistrictsComponent,
  ],
  providers: [ExportService],
})
export class SharedModule {}
