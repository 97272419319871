import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  attempt = 0;
  errorMessage = '';
  retryLimit = 1;

  constructor(private toastr: ToastrService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 500) {
          this.errorMessage =
            'Hubo un problema. Si este problema persiste por favor comunicate con soporte.';
          this.toastr.error(this.errorMessage);
        } else if (error.status == 400) {
          const message: string =
            error.error?.description || error.error?.error;

          this.toastr.error(message);
        } else {
          if (error.error.errors[0]) {
            this.errorMessage = error.error.errors[0];
            this.toastr.error(this.errorMessage);
          }
        }

        return throwError(error);
      })
    );
  }
}
