import { FuseSidebarService } from './../../../../../@fuse/components/sidebar/sidebar.service';
import { Component, OnInit } from '@angular/core';

import { Child } from '@models/child';
import { ChildrenService } from '@services/children.service';
import { StoreService } from '@services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-auth-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  urlLink = 'Inicio';
  icon = 'home';

  children: Child[] = [];

  constructor(
    // eslint-disable-next-line no-unused-vars
    private childrenService: ChildrenService,
    // eslint-disable-next-line no-unused-vars
    private storeService: StoreService,
    // eslint-disable-next-line no-unused-vars
    private router: Router,
    // eslint-disable-next-line no-unused-vars
    private activatedRoute: ActivatedRoute,
    // eslint-disable-next-line no-unused-vars
    private authService: AuthService
  ) {}

  childSelection(event: { value: Child }): void {
    const child = event.value;
    if (child.school.id === environment.SCHOOL_EXTERNA) {
      this.authService.setSchoolType('false');
      window.location.href = `/i/payment-extra?child=${child.id}`;
    } else {
      this.authService.setSchoolType('true');
      window.location.href = `${this.router.url.split('?')[0]}?child=${
        child.id
      }`;
    }
    this.storeService.setChildSelected(child);
  }

  async getChildren(): Promise<void> {
    this.childrenService.getChildren().subscribe((children: Child[]) => {
      this.children = children;

      const { child } = this.activatedRoute.snapshot.queryParams;

      if (child) {
        const childSelected = children.find(
          childItem => childItem.id === child
        );
        if (childSelected) {
          this.storeService.setChildSelected(childSelected);
        }
      } else {
        this.storeService.setChildSelected(children[0]);
      }
    });
  }

  ngOnInit(): void {
    this.getChildren();

    this.activatedRoute.url.subscribe(() => {
      const url = this.router.url;
      switch (url) {
        case '/i/dashboard':
          this.urlLink = 'Inicio de';
          this.icon = 'home';
          break;
        case '/i/children-info':
          this.urlLink = 'Información de';
          this.icon = 'face';
      }
    });
  }

  get selectedChild(): Child {
    return this.storeService.selectedChild.getValue();
  }
}
